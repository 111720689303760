// Gatsby supports TypeScript natively!
import React,{ useEffect,Component } from "react"
import Modal from "react-modal"
import { PageProps } from "gatsby"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../../static/css/dataTables.bootstrap.css"
import "../../static/css/datatable-ajustes.css"
Modal.setAppElement("#___gatsby")
const jQuery = require("jquery")
const axios = require("axios")
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    // marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background:'#313444'
  }
};
const PublicacionesLists4 = () => {
  
    useEffect(() => {
      window.slider();
      window.publicaciones();
  }, [])


return (
  <Layout title="Publicaciones" show_bread={true} >
    <SEO title="Publicaciones" />
    <div className="section mcb-section equal-height publicaciones" style={{paddingTop:'100px', paddingBottom:'60px' }} id="mainp">
        <div className={"section_wrapper mcb-section-inner"}>
            <div className={"wrap mcb-wrap one  valign-top clearfix"}>
                 <div className={"mcb-wrap-inner"}>
                 <div className={"text-center"}>
                 <h3 style={{width:"100%"}}> Estudios</h3>
     
                 </div>
                    <table id="example" className={"table table-striped table-bordered"} style={{width:'100%'}}>
                        <thead>
                        <tr>
                            <th className={"col-sm-3"}>Fecha de publicación</th>
                            <th className={"col-sm-7"}>Documento</th>
                            <th className={"col-sm-2"}>Archivo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td>2020-06-18</td>
                        <td>Situación Actual de la Valuación Independiente en México – CKDs y CERPIs </td>
                        <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/Apalache-situación actual-CKDs-CERPIs.pdf">
                            <i className={"fa fa-download"}></i> Descargar
                        </a>
                        </td>
                    </tr>

                    <tr>
                    <td>2020-04-03</td>

                    <td>COVID-19 e Impacto en Sector Bienes Raíces en México 2020</td>
                    <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/COVID-03-04-20.pdf">
                        <i className={"fa fa-download"}></i> Descargar
                    </a>
                    </td>
                </tr>



                        

                        </tbody>
                    </table>
                </div>
               
            </div>
        </div>
    </div>
    
  </Layout>
)
}


export default PublicacionesLists4
